import { Controller } from 'stimulus';
import Swal from 'sweetalert2';
import { Tooltip } from 'bootstrap';

/* stimulusFetch: 'lazy' */
export default class extends Controller {

    static values = {
        title: { type: String, default: 'Titre' },
        placement: { type: String, default: 'top' },
        container: { type: String, default: 'body' },
        trigger: { type: String, default: 'hover' },
        html: { type: Boolean, default: true },
    }

    connect() {
        this.initializeTooltips();
    }

    initializeTooltips() {

        const tooltip = new Tooltip(this.element, {
            title: this.titleValue,
            placement: this.placementValue,
            // Ajoutez d'autres options ici si nécessaire
            container: this.containerValue,
            trigger: this.triggerValue,
            html: this.htmlValue,

        })

    }

}