export default {
  toolbar: {
    items: [
      'bold',
      'italic',
      'underline',
      // 'link',
      'bulletedList',
      'numberedList',
      'alignment',
      '|',
      'fontColor',
      '|',
      'indent',
      'outdent',
      '|',
      'undo',
      'redo',
      'maximize'
    ],
    shouldNotGroupWhenFull: true,
  },
  // link: {
  //   // Automatically add target="_blank" and rel="noopener noreferrer" to all external links.
  //   addTargetToExternalLinks: true,

  //   // Let the users control the "download" attribute of each link.
  //   decorators: [{
  //     mode: 'automatic',
  //     label: 'Downloadable',
  //     attributes: {
  //       download: 'download'
  //     }
  //   }]
  // },
  language: 'fr',
  // Autres options de configuration...
};