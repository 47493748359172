import { Controller } from 'stimulus';
import Swal from 'sweetalert2';
import { Popover } from 'bootstrap';

/* stimulusFetch: 'lazy' */
export default class extends Controller {

    static values = {
        content: { type: String, default: 'Content' },
        placement: { type: String, default: 'top' },
        container: { type: String, default: 'body' },
        trigger: { type: String, default: 'hover' },
        html: { type: Boolean, default: true },
        delay: { type: Number, default: 400 },
    }

    connect() {
        this.initializeTooltips();
    }

    initializeTooltips() {

        const popover = new Popover(this.element, {
            content: this.contentValue,
            placement: this.placementValue,
            // Ajoutez d'autres options ici si nécessaire
            container: this.containerValue,
            trigger: this.triggerValue,
            html: this.htmlValue,
            delay: this.delayValue

        })

        this.element.addEventListener('shown.bs.popover', function (ev) {
            let oldHandler = popover.hide,
              pel = $(popover.tip);
            pel.on('mouseenter', () => {
              // popover.show()
              popover.hide = () => 1
            });
            pel.on('mouseleave', () => {
              popover.hide()
              popover.hide = oldHandler;
              popover.hide();
      
            });
          })

    }

}