import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['item'];
  static values = {
    url: { type: String, default: null },
    inputPositionSelector: { type: String, default: null },
  }; // Définissez le paramètre 'url' comme une valeur

  connect() {
    // console.log("Le contrôleur est connecté.");
    // console.log("urlValue: ", this.urlValue)
    this.setupDragAndDrop();
  }

  setupDragAndDrop() {
    this.itemTargets.forEach((item) => {
      if (item.querySelector(".draggable-icon")) {
        item.querySelector(".draggable-icon").draggable = true;
        item.querySelector(".draggable-icon").addEventListener('dragstart', (e) => {
          e.dataTransfer.setData('text/plain', '');
          item.classList.add("on_drag");
          this.draggedItem = item;
        });
      }

      item.addEventListener('dragover', (e) => {
        e.preventDefault();
        this.draggedOverItem = item;
        this.addDropHighlight(this.draggedOverItem);
      });

      item.addEventListener('dragleave', (e) => {
        this.removeDropHighlight(this.draggedOverItem); // Supprimez la classe CSS lorsque le survol se termine
      });

      item.addEventListener('drop', (e) => {
        e.preventDefault();
        if (this.draggedItem && this.draggedOverItem) {
          this.reorderList(this.draggedItem, this.draggedOverItem);
        }
        this.removeDropHighlight(this.draggedOverItem); // Supprimez la classe CSS lorsque le survol se termine

      });

      item.addEventListener('dragend', () => {
        this.draggedItem = null;
        this.draggedOverItem = null;
        item.classList.remove("on_drag");
      });

    });
  }

  addDropHighlight(item) {
    item.classList.add('drag-over');
  }

  removeDropHighlight(item) {
    item.classList.remove('drag-over');
  }

  reorderList(draggedItem, draggedOverItem) {
    if (draggedItem !== draggedOverItem) {
      const parent = draggedItem.parentElement;
      const items = Array.from(this.itemTargets);
      const draggedIndex = items.indexOf(draggedItem);
      const droppedIndex = items.indexOf(draggedOverItem);

      console.log(this.getIds());

      if (draggedIndex < droppedIndex) {
        parent.insertBefore(draggedItem, draggedOverItem.nextSibling);
      } else {
        parent.insertBefore(draggedItem, draggedOverItem);
      }
      this.updateBdd();
    }
  }

  moveUp(e) {
    const draggedItem = e.target.closest('.draggable-item');
    const previousItem = draggedItem.previousElementSibling;
    console.log(this.urlValue);

    if (previousItem) {
      draggedItem.parentElement.insertBefore(draggedItem, previousItem);
      this.updateBdd();
    }
  }

  moveDown(e) {
    const draggedItem = e.target.closest('.draggable-item');
    const nextItem = draggedItem.nextElementSibling;
    console.log(this.urlValue);

    if (nextItem) {
      draggedItem.parentElement.insertBefore(nextItem, draggedItem);
      this.updateBdd();
    }
  }

  getIds() {
    const items = Array.from(this.itemTargets);
    const updatedIDs = items.map((item) => item.dataset.id);

    // updatedIDs contient les ID des éléments dans le bon ordre
    // console.log(updatedIDs);

    return updatedIDs

    // Vous pouvez maintenant utiliser updatedIDs pour effectuer la mise à jour AJAX si nécessaire
  }

  updateBdd() {
    if (this.urlValue && this.urlValue != "") {
      const ids = this.getIds()
      // console.log(this);
      // console.log(this.urlValue);
      fetch(this.urlValue, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ids: ids }), // Envoyer la liste d'IDs sous forme de JSON
      })
        .then(response => {
          if (!response.ok) {
            throw new Error('Erreur réseau');
          }
          console.log(response)
          return response.json(); // Convertir la réponse en JSON
        })
        .then(data => {
          // $("#attachedFilesList").html(data.content);
          console.log('success');

        })
        .catch(error => {
          console.error('Erreur:', error);
          throw error;
        });
    }

    console.log(this.inputPositionSelectorValue)
    if (this.inputPositionSelectorValue && this.inputPositionSelectorValue != "") {
      const items = Array.from(this.itemTargets);
      console.log(items);
      items.forEach((item, index) => {
        const positionInput = item.querySelector(this.inputPositionSelectorValue);
        if (positionInput) {
          positionInput.value = index + 1;
        }
      });
    }

  }

}