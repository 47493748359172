import { Controller } from 'stimulus';
import $ from 'jquery';

import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import ckeditorConfig from '../ckeditorConfig.js';

export default class extends Controller {
  static targets = [];
  static values = {}

  initialize() {
    console.log('initialize ck-editor')
    this.hide()
  }

  connect() {
    console.log('connect ck-editor')
    this.hide()
    // console.log('ck-editor stimulus')
    const textarea = this.element.querySelector('textarea')

    if (!textarea) {
      console.log('textarea non trouvé')
      return false;
    }
    if( $(this.element).find('.ck-editor').length == 0) {
      ClassicEditor.create(textarea)
      .then((editor) => {
        this.editor = editor;
        this.element.ckeditorInstance = editor;
        return editor;
      })
      .then(() => {
        this.show()

      })
    }
    else {
      this.show()
    }


  }

  disconnect() {
    console.log('disconnect ck-editor')
    this.hide()
  }

  hide() {
    $(this.element).addClass('d-none')
    $(this.element).hide()
  }

  show() {
    $(this.element).removeClass('d-none')
    $(this.element).delay(100).fadeIn()    
  }


}