import { Controller } from '@hotwired/stimulus';
import CKEDITOR from '@ckeditor/ckeditor5-build-classic';
import Swal from 'sweetalert2';

window.isSpeechRecognitionActive = false;

export default class extends Controller {
    static values = {
        id: { type: String, default: '' }, // Récupère l'ID du champ à mettre à jour
        type: { type: String, default: 'default' }, // Récupère l'ID du champ à mettre à jour
        infoAlreadyShown: { type: Boolean, default: false }, // Récupère l'ID du champ à mettre à jour
    }

    connect() {
        this.voiceInput = false;
        this.initializeSpeechRecognition();
    }

    initializeSpeechRecognition() {
        // Vérifier la compatibilité avec l'API Web Speech
        if (!('SpeechRecognition' in window || 'webkitSpeechRecognition' in window)) {
            alert("Votre navigateur ne supporte pas la reconnaissance vocale.");
            return;
        }

        const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
        const recognition = new SpeechRecognition();
        recognition.lang = 'fr-FR'; // Langue française
        recognition.interimResults = false; // Capture uniquement les résultats définitifs
        recognition.continuous = false; // Arrête la reconnaissance après chaque phrase

        this.voiceInput = document.getElementById(this.idValue); // Champ ciblé par l'ID

        console.log(this.typeValue)
        if (this.typeValue == "ckeditor") {
            const ckeditorElement = document.querySelector('[data-controller="ck-editor"]');
            this.voiceInput = ckeditorElement; // Champ ciblé par l'ID
        }

        console.log(this.voiceInput);
        // const status = this.element.querySelector("[data-status]"); // Élément status
        const startBtn = this.element.querySelector("[data-start-btn]"); // Bouton de démarrage
        const endBtn = this.element.querySelector("[data-end-btn]"); // Bouton de démarrage
        const cancelBtn = this.element.querySelector("[data-cancel-btn]"); // Bouton de démarrage

        startBtn.classList.remove('d-none'); // Changer le texte du bouton
        endBtn.classList.add('d-none'); // Changer le texte du bouton

        // if (!voiceInput || !status || !startBtn) {
        if (!this.voiceInput || !startBtn) {
            console.error("Un ou plusieurs éléments DOM requis sont manquants.");
            return;
        }

        let isListening = false; // Indique si la reconnaissance est active

        // Gestion du clic sur le bouton play
        startBtn.addEventListener('click', () => {
            this.infoAlreadyShown = false;
            if (!window.isSpeechRecognitionActive) {
                recognition.start(); // Démarrer la reconnaissance vocale
                window.isSpeechRecognitionActive = true;
                startBtn.classList.add('d-none'); // Changer le texte du bouton
                endBtn.classList.remove('d-none'); // Changer le texte du bouton
                // status.textContent = 'Écoute en cours... Vous pouvez parler !';
            }
        });

        // Gestion du clic sur le bouton stop
        endBtn.addEventListener('click', () => {
            if (window.isSpeechRecognitionActive) {
                recognition.stop(); // Arrêter la reconnaissance vocale
                window.isSpeechRecognitionActive = false;
                startBtn.classList.remove('d-none'); // Changer le texte du bouton
                endBtn.classList.add('d-none'); // Changer le texte du bouton
                // status.textContent = '';
            }
        });

        // Gestion du clic sur le bouton cancel
        cancelBtn.addEventListener('click', () => {
            if (window.isSpeechRecognitionActive) {
                recognition.stop(); // Arrêter la reconnaissance vocale
                window.isSpeechRecognitionActive = false;
                startBtn.classList.remove('d-none'); // Changer le texte du bouton
                endBtn.classList.add('d-none'); // Changer le texte du bouton
                // status.textContent = '';
            }
            this.setContent("")
        });

        // Ajouter le texte dicté à la textarea
        recognition.onresult = (event) => {
            const text = event.results[0][0].transcript; // Texte dicté
            // voiceInput.value += text + '\n'; // Ajouter le texte dicté avec un saut de ligne
            this.setContent(text)
            // status.textContent = 'Texte ajouté. Cliquez pour continuer ou arrêtez.';
        };

        // Gérer les erreurs
        recognition.onerror = (event) => {
            // status.textContent = `Erreur : ${event.error}`;

            // if (event.error == "no-speech" && !window.isSpeechRecognitionActive) {
            //     recognition.start(); // reDémarrer la reconnaissance vocale
            //     window.isSpeechRecognitionActive = true;
            //     return
            // }

            if (!this.infoAlreadyShown) {
                this.infoAlreadyShown = true;
                // alert('Erreur de reconnaissance vocale: ' + event.error);
                this.sweetAlert(event);
            }
            window.isSpeechRecognitionActive = false;
            startBtn.classList.remove('d-none'); // Changer le texte du bouton
            endBtn.classList.add('d-none'); // Changer le texte du bouton

        };

        // Indiquer que la reconnaissance s'est arrêtée
        recognition.onend = () => {
            if (window.isSpeechRecognitionActive) {
                // Si reconnaissance active, redémarrer
                recognition.start();
                // status.textContent = 'Écoute redémarrée. Parlez !';
            } else {
                // status.textContent = '';
            }
        };
    }

    setContent(data) {
        console.log(this.typeValue)
        if (this.typeValue == "ckeditor") {
            const ckeditorElement = document.querySelector('[data-controller="ck-editor"]');
            this.voiceInput = ckeditorElement; // Champ ciblé par l'ID

            console.log(this.voiceInput);
            console.log(this.voiceInput.ckeditorInstance);
            if (data == "") {
                this.voiceInput.ckeditorInstance.setData(data)
            }
            else {
                data = this.voiceInput.ckeditorInstance.getData() + " " + data
                this.voiceInput.ckeditorInstance.setData(data)
            }

        }
        else {
            if (data == "") {
                this.voiceInput.value = "";

            }
            else {
                this.voiceInput.value += data + '\n';

            }
        }

    }

    sweetAlert(event) {

        if (event.error == "no-speech")
            return

        let content = "<p><center>Erreur de reconnaissance vocale: " + event.error + "</center></p>";
        content += "<p><b>L'erreur provient certainement de :</b></p>";
        content += "<ul class='list-group list-group-numbered'>";
        content += "<li class='list-group-item'>Le navigateur FireFox ne prend pas bien en charge la reconnaissance native: utiliser Edge ou Chrome pour utiliser cette fonctionnalité</li>";
        content += "<li class='list-group-item'>Votre micro est peut être désactivé</li>";
        content += "<li class='list-group-item'>Les autorisations n'ont pas été accordées pour ce site par le navigateur (actualiser la page pour refaire une demande)</li>";
        content += "</ul>";

        Swal.fire({
            toast: false,
            title: "La reconnaissance a échouée",
            html: content,
            icon: 'warning',
            // icon: this.isToastValue || false,
            // iconHtml: '<i class="bi bi-info-square"></i>',
            showCancelButton: true,
            toast: this.isToastValue || false,
            width: this.widthValue || 650,
            hideClass: {
                popup: "animate__animated animate__fadeOut faster",
            },
            didOpen: () => {
            },
        })
    }
}
