// Jquery
const $ = require('jquery');

// create global $ and jQuery variables
global.$ = global.jQuery = $;
window.jQuery = $;

// start the Stimulus application
import './bootstrap';

import * as bootstrap from 'bootstrap'

import 'select2';

require('bootstrap-icons/font/bootstrap-icons.css');


import { ClassicEditor } from '@ckeditor/ckeditor5-build-classic';
import ckeditorConfig from './ckeditorConfig.js';

// LOAD ADMIN STYLE
import './styles/app.scss';

var fixeHeaderElms = document.getElementsByClassName("sticky-top");

function padHeaderElement() {

    // Sort our elements based on the data-header-index
    fixeHeaderElms = Array.from(fixeHeaderElms).sort((a, b) => {
        return parseInt(a.dataset.headerIndex) - parseInt(b.dataset.headerIndex)
    });

    var fixedHeaderHeights = 0;
    Array.from(fixeHeaderElms).forEach(
        function (element, index, array) {
            if (fixedHeaderHeights > 0) {
                element.style.top = fixedHeaderHeights + "px";
            }
            fixedHeaderHeights += element.offsetHeight;
        }
    );

    // Padd the page (or the body container) so the menu's don't overlap the body content.
    // if (fixedHeaderHeights > 0) { document.getElementsByClassName("body")[0].style.top = fixedHeaderHeights + "px"; }
}

// Throw an observer on something controlling the size change of the menu.
if (fixeHeaderElms.length > 0) {
    new ResizeObserver(padHeaderElement).observe(fixeHeaderElms[0]);
}

function loadDemande(id) {
    var $this = this

    return new Promise(function (resolve, reject) {

        $.ajax({
            url: "/app/demandes/" + id + "/afficher",
            type: "GET",
            success: function (data) {

                const $tempDiv = $('<div>').html(data);

                // Utilisez jQuery pour récupérer l'élément avec l'ID souhaité.
                const $demandeContent = $tempDiv.find('#demandeContent')[0].innerHTML;
                const $demandeStatus = $tempDiv.find('#demandeStatus')[0].innerHTML;
                const $demandeHistorique = $tempDiv.find('#demandeHistorique')[0].innerHTML;

                resolve($demandeStatus + "<hr>" + $demandeContent + $demandeHistorique);
            },
            error: function (erro) {
                reject("Le contenu n'a pas été trouvé");
            }
        });

    });

}

$(document).ready(function ($) {

    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new bootstrap.Tooltip(tooltipTriggerEl, {
        container: 'body',
        trigger: 'hover'
      });
    })
    
    $('.demande-modal').on('show.bs.modal', function () {
        displayDemandeModalContent(this)
    });


    // 
    // Sélectionnez le checkbox principal
    var $checkboxAll = $('.form-batch-checkbox-all');
    var $checkboxes = $('.form-batch-checkbox');
    var $tdCheckable = $('.td-checkable');
    var $linksBulkAction = $('.bulk-action .nav-link');
    onChangeCheckbox();

    // Gérez l'événement click du checkbox principal
    $checkboxAll.on('click', function () {
        // Récupérez l'état actuel du checkbox principal
        var isChecked = $(this).prop('checked');

        // Sélectionnez tous les autres checkboxes

        // Parcourez chaque checkbox
        $checkboxes.each(function () {
            // Cochez ou décochez le checkbox en fonction de l'état actuel du checkbox principal
            $(this).prop('checked', isChecked);
        });
    });

    // Gérez l'événement change sur les cases à cocher
    $checkboxes.on('change', function () {
        onChangeCheckbox()
    });
    $tdCheckable.on('click', function () {
        $(this).parent().find('.form-batch-checkbox').click()
        onChangeCheckbox()
    });
    $checkboxAll.on('change', function () {
        onChangeCheckbox()
    });

    function onChangeCheckbox() {
        $checkboxes.each(function () {

            if ($(this).is(":checked")) {
                $(this).closest('tr').addClass('active');
            }
            else {
                $(this).closest('tr').removeClass('active')
            }
            // Cochez ou décochez le checkbox en fonction de l'état actuel du checkbox principal
        });
        if (checkOneLineSelected()) {
            $linksBulkAction.removeClass('disabled')
        } else {
            $linksBulkAction.addClass('disabled')
        }
    }

    function checkOneLineSelected() {
        if ($('.form-batch-checkbox:checked').length > 0) {
            return true
        }
        return false
    }

    var $boutonBulkexport = $('#bulkexport');

    // Gérez l'événement click du bouton
    $boutonBulkexport.on('click', function () {
        // Sélectionnez toutes les cases à cocher cochées
        var $link = $boutonBulkexport.attr('href');
        var $casesCochees = $('.form-batch-checkbox:checked');

        // Créez un tableau pour stocker les valeurs des data-id
        var values = [];

        // Parcourez les cases cochées pour récupérer les valeurs
        $casesCochees.each(function () {
            var value = $(this).val();
            values.push(value);
        });

        var valuesString = values.join(',');

        // Construisez l'URL avec les paramètres
        var url = $link + '?ids=' + valuesString;
        console.log(url)

        // Redirigez l'utilisateur vers l'URL construite
        window.location.href = url;
    });

    var $boutonBulkdownload = $('#Bulkdownload');

    // Gérez l'événement click du bouton
    $boutonBulkdownload.on('click', function () {
        // Sélectionnez toutes les cases à cocher cochées
        var $link = $boutonBulkdownload.attr('href');
        var $casesCochees = $('.form-batch-checkbox:checked');

        // Créez un tableau pour stocker les valeurs des data-id
        var values = [];

        // Parcourez les cases cochées pour récupérer les valeurs
        $casesCochees.each(function () {
            var value = $(this).val();
            values.push(value);
        });

        var valuesString = values.join(',');

        // Construisez l'URL avec les paramètres
        var url = $link + '?ids=' + valuesString;
        console.log(url)

        // Redirigez l'utilisateur vers l'URL construite
        window.location.href = url;
    });

    // initialiserEditeur();
    // initialiserEditeur();
    // initialiserEditeur();
    // initialiserEditeur();



})
let isEditeurInitialise = false;
async function initialiserEditeur() {
    try {
        if (!isEditeurInitialise) {
            isEditeurInitialise = true; // Marquer l'éditeur comme initialisé
            const editor = await ClassicEditor
              .create(document.querySelector('#editor-test'))
              .then((editor) => {
                console.log(editor);
                window.testEditor = editor;
                editor.setData("coucou");
              });
      
            return editor; 
        }
        else {
            console.log("L'éditeur a déjà été initialisé.");
            return "";
        }

    } catch (error) {
        console.error("Une erreur s'est produite lors de l'initialisation de l'éditeur :", error);
    }
}

