import { Controller } from 'stimulus';
import { Modal } from 'bootstrap';
import $ from 'jquery';
import { useDispatch } from 'stimulus-use';

import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import ckeditorConfig from '../ckeditorConfig.js';

export default class extends Controller {
  static targets = ['modal', 'modalBody', 'editor'];
  static values = {
    formUrl: String,
    formId: String,
  }
  modal = null;
  connect() {
    useDispatch(this);
    // this.element.addEventListener('turbo:submit-end', (event) => {
    //   console.log(event);
    //   if (event.detail.success) {
    //     this.modal.hide();
    //   }
    // });
    this.boundBeforeFetchResponse = this.beforeFetchResponse.bind(this);
    this.boundSubmitEnd = this.submitEnd.bind(this);
    this.boundFrameload = this.frameLoad.bind(this);
    document.addEventListener('turbo:before-fetch-response', this.boundBeforeFetchResponse);
    document.addEventListener('turbo:submit-end', this.boundSubmitEnd);
    document.addEventListener('turbo:frame-load', this.boundFrameload);

  }

  disconnect() {
    document.removeEventListener('turbo:before-fetch-response', this.boundBeforeFetchResponse);
    document.removeEventListener('turbo:submit-end', this.boundSubmitEnd);
    document.removeEventListener('turbo:frame-load', this.boundFrameload);
  }

  async openTheModal(event) {
    console.log("openTheModal");
    console.log(this.modalTarget);
    console.log("Dans openMal : " + this.formIdValue);
    this.modal = new Modal(this.modalTarget);
    this.modal.show();
  }

  changeSrc(newSrc) {
    this.modalBodyTarget.src = newSrc;
  }

  openModal(event) {

    this.openTheModal(event)
      .then(() => {
        console.log("openedModal");

        // if(window.myEditor) {
        //   window.myEditor.destroy().then(() => {window.myEditor = undefined})
        // }

        // var setTimeout = window.setTimeout(() => {
        // const textarea = document.querySelector('#' + event.params.id + " #task_description")

        // console.log('event.params : ' + event.params)
        // console.log('#' + event.params.id + " #task_description")
        // if(!textarea) {
        //   console.log('textarea non trouvé')
        //   return false;
        // }
        // ClassicEditor.create(textarea)
        //   .then((editor) => {
        //     // console.log(editor);
        //     window.myEditor = editor;
        //     return editor;
        //   })
        // },1000)

      }
      )
  }

  beforeFetchResponse(event) {
    if (!this.modal || !this.modal._isShown) {
      return;
    }
    const fetchResponse = event.detail.fetchResponse;
    if (fetchResponse.succeeded && fetchResponse.redirected) {
      event.preventDefault();
      Turbo.visit(fetchResponse.location);
    }
  }

  submitEnd(event) {
    // alert('submitEnd')
  }

  frameLoad(event) {
    // alert('frameLoad')
    // if (!this.editorValue || !window.editor) {
      // console.log("event.params : ", event)



  }

  async initialiserEditeur() {

    // this.modalBodyTarget


    try {
      // if (!this.editorIsInitValue) {
      window.editorIsInitValue = true; // Marquer l'éditeur comme initialisé
      console.log("Dans initialiserEditeur : " + this.formIdValue)
      // console.log(this.editorTarget)
      if (this.formIdValue) {
        // const textarea = this.editorTarget;
        const textarea = document.querySelector('#' + this.formIdValue + " #task_description")
        console.log(textarea)
        if (textarea) {
          const editor = await ClassicEditor
            .create(textarea)
            .then((editor) => {
              // console.log(editor);
              window.myEditor = editor;
              return editor;
            })
            ;

        }
      }
      // }
      // else {
      //   console.log("L'éditeur a déjà été initialisé.");
      //   return "";
      // }

    } catch (error) {
      console.error("Une erreur s'est produite lors de l'initialisation de l'éditeur :", error);
    }
  }

}