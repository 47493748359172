import { Controller } from 'stimulus';
import Swal from 'sweetalert2';

/* stimulusFetch: 'lazy' */
export default class extends Controller {

    static values = {
        action: String, // "submit" | "link" 
        formSelector: String,
        urlLink: String, // url 
        title: String,
        icon: String,
        inputVal: String,
        inputLabel: String,
        html: String,
        confirmButtonText: String,
        focusSelector: String,

        showClass: String,

    }

    connect() {
        if (this.actionValue == "onStart") {
            this.showClassValue = {
                popup: "animate__animated animate__fadeIn faster",
                icon: 'animate__animated animate__fadeIn faster'
            }
            this.showAlertModal()
        }

    }

    myToast() {
        return Swal.mixin({
            title: "Demande en cours",
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        })
    }

    sweetAlert(event) {
        event.preventDefault();
        Swal.fire({
            toast: false,
            title: this.titleValue || null,
            html: this.htmlValue || null,
            icon: this.iconValue || 'info',
            // icon: this.isToastValue || false,
            // iconHtml: '<i class="bi bi-info-square"></i>',
            showCancelButton: true,
            confirmButtonText: this.confirmButtonTextValue || 'Oui',
            cancelButtonText: this.cancelButtonTextValue || 'Annuler',
            showLoaderOnConfirm: true,
            toast: this.isToastValue || false,
            width: this.widthValue || 650,
            hideClass: {
                popup: "animate__animated animate__fadeOut faster",
            },
            didOpen: () => {
                // console.log(this.focusSelectorValue)
                if(this.focusSelectorValue) {
                    var v = $(""+this.focusSelectorValue).val();        
                    $(""+this.focusSelectorValue).focus().val('').val(v);
                }
            },
            preConfirm: () => {
                if (this.actionValue == "submit") {
                    return this.submitForm();
                }
                if (this.actionValue == "submitHtml") {
                    return this.submitHtmlForm();
                }
                if (this.actionValue == "link") {
                    return this.link(this.urlLinkValue);
                }
            }
        })
    }

    submitForm() {
        // console.log(this.element);
        if (!this.submitAsyncValue) {

            // console.log($(this.element).closest("form"));
            $(this.element).closest("form").submit();
            return;
        }
        return fetch(this.element.action, {
            method: this.element.method,
            body: new URLSearchParams(new FormData(this.element)),
        });
    }

    submitHtmlForm() {
        // console.log(this.formSelectorValue);
        if (this.formSelectorValue) {
            $("" + this.formSelectorValue).submit();
            return;
        }
    }

    submitEmailForm(macineName) {
        $("#my_email_form").submit();
        return;
    }

    link(url) {
        window.location = url;
    }

    post(url, data) {
        return fetch(url, {
            method: 'POST',
            body: JSON.stringify(data), // Convertir les données en JSON si nécessaire
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Erreur réseau');
                }
                return response.json(); // Retourne la réponse sous forme de JSON si nécessaire
            })
            .catch(error => {
                console.error('Erreur:', error);
                throw error;
            });
    }

    getPiecesJointes(url) {
        fetch(url, {
            method: 'GET',
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Erreur réseau');
                }
                return response.json(); // Convertir la réponse en JSON
            })
            .then(data => {
                $("#attachedFilesList").html(data.content);
            })
            .catch(error => {
                console.error('Erreur:', error);
                throw error;
            });
    }

}