import { Controller } from '@hotwired/stimulus';

/*
 * This is an example Stimulus controller!
 *
 * Any element with a data-controller="hello" attribute will cause
 * this controller to be executed. The name "hello" comes from the filename:
 * hello_controller.js -> "hello"
 *
 * Delete this file or adapt it for your use!
 */
export default class extends Controller {
    static targets = ['button'];
    static values = {
        targetId: String,
        newSrc: String
    }

    changeSource() {

        const targetController = this.application.getControllerForElementAndIdentifier(
            document.getElementById(this.targetIdValue),
            'modal-form'
        );

        console.log(targetController)

        if (targetController) {
            targetController.changeSrc(this.newSrcValue);
            targetController.openModal(event);
        }
    }
}
