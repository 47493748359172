import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ["collectionContainer","itemCollection"]

    static values = {
        index    : Number,
        prototype: String,
    }

    connect() {
      }

    addCollectionElement(event)
    {
        // const nbElement = this.collectionContainerTarget.querySelector('.item');

        const item = document.createElement('div');
        item.classList.add('card')
        item.classList.add('draggable-item')
        item.classList.add('prototype_list_element')
        item.classList.add('mb-3')
        item.innerHTML = this.prototypeValue.replace(/__name__/g, this.indexValue);
        this.collectionContainerTarget.appendChild(item);
        this.addDeleteAction(item)

        const prototypeListElements = this.collectionContainerTarget.querySelectorAll('.prototype_list_element');
        // Convertir NodeList en Array si vous avez besoin de méthodes de tableau supplémentaires
        const elementsArray = Array.from(prototypeListElements);
    
        elementsArray.forEach((element, index) => {
          console.log(`Element ${index + 1}`, element);
          const positionInput = element.querySelector('.item-position');
          if (positionInput) {
            positionInput.value = index + 1;
          }
        });


        this.indexValue++;
    }

    addDeleteAction(item) {
        const removeFormButton = item.querySelector('button');
        removeFormButton.addEventListener('click', (e) => {
            e.preventDefault();
            item.remove();
        });
    }

    removeElement(event){
        console.log(this.itemCollectionTarget);
        this.itemCollectionTarget.remove();
    }
}